import Button from '../../components/button';
import { useFlowStep } from '@components/flow';
import { useCallback, useEffect, useState } from 'react';
import { Footer } from '../../components/footer';
import ShortcutTile from '@components/shortcut-tile';
import styles from './style.module.scss';
import shortcutsConfig from './shortcuts-config';
import PopularStoriesTile from '@components/popular-stories-tile';
import { CapiPosts } from 'src/types/capi';
import { trackEvent as doTrackEvent, trackModule as doTrackModule } from '../../analytics';
import getConfig from '@utils/getConfig';

const { biUrl } = getConfig();

export const MissingOut: () => JSX.Element = () => {
  const trackEvent = (label: string) =>
    doTrackEvent({
      eventAction: 'cancellation_fomo',
      element_name: 'cancellation_fomo',
      eventLabel: label,
      action: label,
    });

  const trackModule = ({ label, index, url }: { label: string; index: number; url: string }) =>
    doTrackModule({
      eventLabel: label,
      index,
      click_text: label,
      click_url: url,
      eventAction: 'cancellation_fomo',
      element_name: 'cancellation_fomo',
    });

  const setStep = useFlowStep((flowStep) => flowStep.setStep);
  const context = useFlowStep((flowStep) => flowStep.context);

  const [popularStories, setPopularStories] = useState<CapiPosts[]>([]);

  const fetchPopularStories = async () => {
    const res = await fetch(
      `${biUrl}/ajax/content-api/posts?page[limit]=4&filter[category]={insider-investigates,bi-prime}`
    );
    const { data } = await res.json();
    setPopularStories(data);
  };

  const applyPopularStories = () => {
    if (popularStories.length) {
      return popularStories.map((story, index) => (
        <PopularStoriesTile
          key={story.id}
          title={story.attributes.title}
          vertical={story.relationships.verticals.data[0]?.id}
          thumbnail={story.relationships.thumbnails.data[0]?.links.self}
          url={story.links.site}
          verticalUrl={story.relationships.verticals.data[0]?.links.site}
          trackingFnc={() =>
            trackModule({ label: story.attributes.title, index: index + 1, url: story.links.site })
          }
        />
      ));
    }
  };

  useEffect(() => {
    return () => {
      if (context !== 'keep-my-subscription') {
        return;
      }

      trackEvent('keep_subscription');
    };
  }, [context]);

  useEffect(() => {
    fetchPopularStories();
    trackEvent('page_loads');
  }, []);

  const handleNext = useCallback(() => {
    trackEvent('next');
    setStep('upgrade');
  }, []);

  const handleBack = useCallback(() => {
    trackEvent('go_back');
  }, []);

  return (
    <>
      <div className={styles['screen-wrapper']}>
        <div className={styles['missing-out-wrapper']}>
          <div className={styles['header']}>
            When you leave Insider, you also lose unlimited access to all of our content and tools.
          </div>

          <div className={styles['sub-header']}>exclusive tools, databases, and events</div>
          <div className={styles['shortcut-grid']}>
            {shortcutsConfig.map((shortcut, index) => (
              <ShortcutTile
                key={shortcut.title}
                {...shortcut}
                imgClass={styles[shortcut.imgClass]}
                trackingFnc={() =>
                  trackModule({
                    label: shortcut.trackingLabel,
                    index: index + 1,
                    url: shortcut.url,
                  })
                }
              />
            ))}
          </div>
          <div className={styles['sub-header']}>unlimited access to all stories</div>
          <div className={styles['popular-stories-grid']}>
            {popularStories && applyPopularStories()}
          </div>
        </div>
      </div>
      <Footer
        onBack={handleBack}
        classes={{
          footer: 'cancelation-flow-footer',
          progressBar: styles['cancelation-flow-progress-bar'],
          backButton: styles['cancelation-flow-back'],
        }}>
        <Button color='black' type='button' onClick={handleNext}>
          Next
        </Button>
      </Footer>
    </>
  );
};
