// Lib
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { sessionTimer } from '@utils';
import log from '@utils/analytics';
import { getEnterpriseStatus } from '@auth';
import useCalculateSubtotal from './hooks/useCalculateSubtotal';
import { updateEnterprise } from './group-name/requests';

// Components
import Masthead from '@components/masthead';
import Modal from '@components/modal';
import Button from '@components/button';
import { useUser } from '@stores/user';
import { useEnterprise } from '@stores/enterprise';
import SeatCount from './seat-count';
import GroupName from './group-name';
import Payment from './payment';
import OrderSummary from './order-summary/';
import CheckoutSuccess from './success';
import CheckoutError from './errors';

// Assets
import './style.scss';
import getConfig from '@utils/getConfig';
import usePushIds from '@utils/usePushIds';

const track = (eventLabel) => {
  log.track({
    eventCategory: 'membership_corporate_checkout_flow',
    eventAction: 'checkout_page',
    eventLabel,
  });
};

const Checkout = () => {
  usePushIds();

  const user = useUser((state) => state.user);
  const enterpriseId = useEnterprise((state) => state.enterpriseId);
  const { api, basicToken, biUrl } = getConfig();

  /** Enterprise **/
  // TODO: Check if it's worthy having this status in the store instead.
  const [enterpriseStatus, setEnterpriseStatus] = useState(null);
  useEffect(() => {
    if (!user) {
      return;
    }

    const getData = async () => {
      const token = user.token;
      if (!enterpriseId || !token) {
        setEnterpriseStatus('inactive');
        return;
      }

      const status = await getEnterpriseStatus(enterpriseId, token);
      setEnterpriseStatus(status);
    };
    getData();
  }, [user, enterpriseId]);

  useEffect(() => {
    track('loads');
  }, []);

  const [pageStatus, setPageStatus] = useState('CHECKOUT'); // CHANGE THIS VALUE TO TRIGGER ERROR STATES
  const [pricingTable, setPricingTable] = useState(null);
  const [stepTracker, setstepTracker] = useState({
    seatCount: 'complete',
    groupName: 'enabled',
    payment: 'disabled',
  });
  const [seats, setSeats] = useState(null);
  const [seatChange, setSeatChange] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [subId, setSubId] = useState(null);
  const [taxAmount, setTaxAmount] = useState(null);

  useEffect(() => {
    if (enterpriseStatus === 'active' && pageStatus !== 'SUCCESS') {
      setPageStatus('EXISTING_ACCOUNT');
    }
  }, [enterpriseStatus, pageStatus]);

  const handleEditClick = (step) => {
    setstepTracker((currState) => {
      if (step === 'seatCount') {
        return {
          seatCount: 'enabled',
          groupName: currState.groupName === 'complete' ? 'complete' : 'disabled',
          payment: 'disabled',
        };
      }
      if (step === 'groupName') {
        return {
          seatCount: 'complete',
          groupName: 'enabled',
          payment: 'disabled',
        };
      }
    });
  };

  const handleChangeSeats = (e) => {
    setSeats((currSeats) => {
      const newSeats = Number(e.target.value);
      if (!isNaN(newSeats) && newSeats >= 0) return newSeats;
      return currSeats;
    });
  };

  const handleDecrement = () => {
    setSeats((currSeats) => {
      if (currSeats > 0) return (currSeats -= 1);
      return currSeats;
    });
  };

  const handleIncrement = () => {
    setSeats((currSeats) => (currSeats += 1));
  };

  const handleConfirmSeats = () => {
    if (enterpriseId && sessionStorage.getItem('seatCount') !== seats) {
      updateEnterprise(groupName, seats, user.token, enterpriseId);
      setSeatChange(true);
      sessionStorage.setItem('seatChange', true);
    }
    sessionStorage.setItem('seatCount', seats);
    track('confirm_seats');
    setstepTracker((currState) => {
      return {
        seatCount: 'complete',
        groupName: currState.groupName === 'complete' ? 'complete' : 'enabled',
        payment: currState.groupName === 'complete' ? 'enabled' : 'disabled',
      };
    });
  };

  const handleConfirmName = () => {
    sessionStorage.setItem('groupName', groupName);
    setstepTracker((currState) => {
      return { ...currState, groupName: 'complete', payment: 'enabled' };
    });
  };

  const handleChangeName = (newName) => {
    setGroupName(newName);
  };
  const calculate = useCalculateSubtotal();

  const startSessionTimer = async () => {
    setPageStatus(await sessionTimer(24));
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    const storage = {
      seats: sessionStorage.getItem('seatCount'),
      groupName: sessionStorage.getItem('groupName'),
    };

    // ** Scenario: Seats appear in query param **

    if (queryParams.seats) {
      setSeats(Number(queryParams.seats));
      sessionStorage.setItem('seatCount', queryParams.seats);
      window.history.replaceState({}, '', '/checkout');
    }

    // ** Scenario: Seats appear only in storage **

    if (!queryParams.seats && storage.seats) {
      setSeats(Number(storage.seats));
    }

    // ** Scenario: No seats data available **

    if (!queryParams.seats && !storage.seats) {
      // Set to minimum seat count
      setSeats(2);
      // Determine if groupName step should be disabled or complete
      if (storage.groupName) {
        setstepTracker({
          seatCount: 'enabled',
          groupName: 'complete',
          payment: 'disabled',
        });
      } else {
        setstepTracker({
          seatCount: 'enabled',
          groupName: 'disabled',
          payment: 'disabled',
        });
      }
    }

    if (storage.groupName) {
      setGroupName(storage.groupName);
    }

    startSessionTimer();
  }, []);

  useEffect(() => {
    const getPlan = async () => {
      try {
        const resp = await fetch(
          `${api}/v1.0/plans/3fe932aa-1d63-45d6-9e84-8ba2f1aaea21?include=prices`,
          {
            method: 'GET',
            headers: { Authorization: `Basic ${basicToken}` },
          }
        );
        const json = await resp.json();
        const { prices: pricingTableData } = json.data.attributes;
        setPricingTable(pricingTableData);
      } catch (e) {
        console.error(e.message);
      }
    };
    getPlan();
  }, []);

  return (
    <>
      <Helmet title='My Insider | Corporate Subscription Checkout' />
      <Masthead skipButton={false} colorScheme='variant' />
      {user.hasPrime === 1 && pageStatus !== 'SUCCESS' && (
        <Modal showHeader>
          <h3 className='modal__title'>Before we get started...</h3>
          <p>We’ve noticed that {user.email} is already associated with an Insider subscription.</p>
          <p>
            Proceed with checkout and contact{' '}
            <a href='mailto:group-subscriptions@insider.com'>group-subscriptions@insider.com</a> to
            cancel your previous subscription.
          </p>
          <Button type='button' color='light-blue' modal-role='close' className='modal__cta'>
            Got it
          </Button>
        </Modal>
      )}
      {pageStatus === 'CHECKOUT' && (
        <main className='checkout__screen'>
          <section className='checkout__subheader'>
            <a className='checkout__nav-back' href={biUrl} onClick={() => track('back_to_insider')}>
              Back to Business Insider
            </a>
            <h1 className='checkout__title'>Checkout</h1>
            <span className='checkout__help'>
              Need help?&nbsp;
              <a href='mailto:group-subscriptions@insider.com' onClick={() => track('contact_us')}>
                Contact us
              </a>
            </span>
          </section>
          <div className='checkout__grid'>
            <div className='checkout__column'>
              <SeatCount
                seats={seats}
                handleChange={handleChangeSeats}
                handleConfirmSeats={handleConfirmSeats}
                handleDecrement={handleDecrement}
                handleIncrement={handleIncrement}
                handleEditClick={handleEditClick}
                status={stepTracker.seatCount}
              />
              <GroupName
                groupName={groupName}
                handleChange={handleChangeName}
                handleEditClick={handleEditClick}
                seats={seats}
                handleConfirmName={handleConfirmName}
                status={stepTracker.groupName}
                taxAmount={taxAmount}
              />
              <Payment
                seatStatus
                status={stepTracker.payment}
                enterpriseStatus={enterpriseStatus}
                taxAmount={taxAmount}
                setTaxAmount={setTaxAmount}
                setPageStatus={setPageStatus}
                setSubId={setSubId}
                seatChange={seatChange}
                setSeatChange={setSeatChange}
              />
            </div>
            <section className='order-summary__wrapper'>
              <OrderSummary
                purchaseDescription='Group Subscription - Yearly'
                subtotal={calculate(seats, pricingTable)}
                taxes={taxAmount}
                isThankYou={false}
              />
              <p className='tax-exempt-notice'>
                If your organization is tax exempt, please contact{' '}
                <a
                  href='mailto:group-subscriptions@insider.com?subject=Tax Exemption'
                  onClick={() => track('tax_exempt')}>
                  group-subscriptions@insider.com
                </a>{' '}
                to process your subscription
              </p>
            </section>
          </div>
        </main>
      )}
      {pageStatus === 'SUCCESS' && (
        <CheckoutSuccess
          setPageStatus={setPageStatus}
          purchaseDescription='Group Subscription - Yearly'
          subtotal={calculate(seats, pricingTable)}
          taxes={taxAmount}
          subId={subId}
        />
      )}

      {pageStatus !== 'CHECKOUT' && pageStatus !== 'SUCCESS' && <CheckoutError type={pageStatus} />}
    </>
  );
};

export default Checkout;
