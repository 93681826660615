import { useEffect } from 'react';
import log from '@utils/analytics';

import './style.scss';

const Welcome = (): JSX.Element => {
  useEffect(() => {
    log.track({
      eventCategory: 'membership_corporate_onboarding_flow',
      eventAction: 'onboarding_welcome',
      eventLabel: 'loads',
    });
  }, []);

  return (
    <div className='welcome'>
      <main>
        <h2>Welcome to Insider!</h2>
        <p>
          You now have unlimited access to all of our articles, live events and other benefits. Find
          out how you can best experience your subscription and welcome your team to Insider. As
          part of your onboarding experience, you can personalize your account and add members to
          your group.
        </p>
      </main>
      <div className='blue-bar-top' />
      <div className='blue-bar-bot' />
    </div>
  );
};

export default Welcome;
