import './style.scss';
import { useEffect } from 'react';

import TextInput from '@components/text-input';
import log from '@utils/analytics';
import { useUser } from '@stores/user';
import { usePersonalization } from '@stores/personalize';

const Personalize = (): JSX.Element => {
  const { user, refresh } = useUser();
  const {
    firstname,
    lastname,
    jobTitle,
    industry,
    setFirstname,
    setLastname,
    setJobTitle,
    setIndustry,
  } = usePersonalization();

  useEffect(() => {
    log.track({
      eventCategory: 'membership_corporate_onboarding_flow',
      eventAction: 'personalize_experience',
      eventLabel: 'loads',
    });
    refresh();
    if (user?.firstname) setFirstname(user.firstname);
    if (user?.lastname) setLastname(user.lastname);
    if (user?.jobTitle) setJobTitle(user.jobTitle);
    if (user?.industry) setIndustry(user.industry);
  }, []);

  return (
    <>
      <div className='personalize__wrapper'>
        <h2 className='personalize__header'>Personalize your experience</h2>
        <p className='personalize__sub-header'>
          For a better Insider experience, tell us a little bit more about yourself.
        </p>

        <div className='personalize__grid-parent'>
          <TextInput
            name='firstname'
            fullLabel='First name (Optional)'
            abrevLabel='First name'
            value={firstname || ''}
            onChangeInput={(e: Event) => {
              const target = e.target as HTMLInputElement;
              setFirstname(target.value);
            }}
            onInputFocus={() =>
              log.track({
                eventCategory: 'membership_corporate_onboarding_flow',
                eventAction: 'personalize_experience',
                eventLabel: 'first_name',
              })
            }
          />
          <TextInput
            name='lastname'
            fullLabel='Last name (Optional)'
            abrevLabel='Last name'
            value={lastname || ''}
            onChangeInput={(e: Event) => {
              const target = e.target as HTMLInputElement;
              setLastname(target.value);
            }}
            onInputFocus={() =>
              log.track({
                eventCategory: 'membership_corporate_onboarding_flow',
                eventAction: 'personalize_experience',
                eventLabel: 'last_name',
              })
            }
          />
          <TextInput
            name='jobTitle'
            fullLabel='Job title (Optional)'
            abrevLabel='Job title'
            value={jobTitle || ''}
            onChangeInput={(e: Event) => {
              const target = e.target as HTMLInputElement;
              setJobTitle(target.value);
            }}
            onInputFocus={() =>
              log.track({
                eventCategory: 'membership_corporate_onboarding_flow',
                eventAction: 'personalize_experience',
                eventLabel: 'job_title',
              })
            }
          />
          <TextInput
            name='industry'
            fullLabel='Industry (Optional)'
            abrevLabel='Industry'
            value={industry || ''}
            onChangeInput={(e: Event) => {
              const target = e.target as HTMLInputElement;
              setIndustry(target.value);
            }}
            onInputFocus={() =>
              log.track({
                eventCategory: 'membership_corporate_onboarding_flow',
                eventAction: 'personalize_experience',
                eventLabel: 'industry',
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default Personalize;
